// any CSS you import will output into a single css file (app.css in this case)
import './styles/base.css';
import 'intl-tel-input/build/css/intlTelInput.css';

// start the Stimulus application
import './bootstrap';

import 'flowbite';
import 'flowbite/dist/datepicker.min';
import intlTelInput from 'intl-tel-input'; // eslint-disable-line import/default

/* global FlowbiteInstances:readonly */

window.document.querySelectorAll('input[type="tel"]').forEach((input) => {
    intlTelInput(input, {
        loadUtilsOnInit: () => import('intl-tel-input/utils'), // eslint-disable-line import/no-unresolved
        initialCountry: 'auto',
        geoIpLookup: function (success, failure) {
            fetch('https://ipapi.co/json')
                .then((res) => {
                    return res.json();
                })
                .then((data) => {
                    success(data.country_code);
                })
                .catch(() => {
                    failure();
                });
        },
        separateDialCode: true,
        strictMode: true,
        hiddenInput: function (telInputName) {
            return {
                phone: telInputName,
            };
        },
    });
});

const $clipboardElements = document.getElementsByClassName('copy-to-clipboard');

for (let i = 0; i < $clipboardElements.length; i++) {
    const $el = $clipboardElements[i];

    const clipboard = FlowbiteInstances.getInstance('CopyClipboard', $el.id);
    const tooltip = FlowbiteInstances.getInstance('Tooltip', 'tooltip-copy-' + $el.id);
    const $defaultTooltipMessage = document.getElementById('default-tooltip-message-' + $el.id);
    const $successTooltipMessage = document.getElementById('success-tooltip-message-' + $el.id);
    const $button = document.getElementById('button-' + $el.id);
    const successClasses = ['focus:ring-green-300', 'hover:bg-green-700', 'active:bg-green-800', '!bg-green-600'];
    const dangerClasses = ['focus:ring-primary-300', 'hover:bg-primary-700', 'active:bg-primary-800', 'bg-primary-600'];

    clipboard.updateOnCopyCallback(() => {
        successClasses.forEach((className) => {
            $button.classList.add(className);
        });
        dangerClasses.forEach((className) => {
            $button.classList.remove(className);
        });
        $defaultTooltipMessage.classList.add('hidden');
        $successTooltipMessage.classList.remove('hidden');
        tooltip.show();

        // reset to default state
        setTimeout(() => {
            successClasses.forEach((className) => {
                $button.classList.remove(className);
            });
            dangerClasses.forEach((className) => {
                $button.classList.add(className);
            });
            $defaultTooltipMessage.classList.remove('hidden');
            $successTooltipMessage.classList.add('hidden');
            tooltip.hide();
        }, 2000);
    });
}

export function getTokenIcon(tokens) {
    switch (true) {
        case tokens >= 1000:
            return '/assets/icons/billing-flow-update/token-pack-with-sparkles.svg';
        case tokens >= 750:
            return '/assets/icons/billing-flow-update/token-packs/token-pile-9.svg';
        case tokens >= 500:
            return '/assets/icons/billing-flow-update/token-packs/token-pile-8.svg';
        case tokens >= 400:
            return '/assets/icons/billing-flow-update/token-packs/token-pile-7.svg';
        case tokens >= 300:
            return '/assets/icons/billing-flow-update/token-packs/token-pile-6.svg';
        case tokens >= 200:
            return '/assets/icons/billing-flow-update/token-packs/token-pile-5.svg';
        case tokens >= 100:
            return '/assets/icons/billing-flow-update/token-packs/token-pile-4.svg';
        case tokens >= 50:
            return '/assets/icons/billing-flow-update/token-packs/token-pile-3.svg';
        case tokens >= 25:
            return '/assets/icons/billing-flow-update/token-packs/token-pile-2.svg';
        default:
            return '/assets/icons/billing-flow-update/token-packs/token-pile-1.svg';
    }
}
